// *****ZASTUPSTVA BUTTONS***** //
.zastupstva-grid-btn {
  width: 100%;
  padding: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  .zastupstva-box {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    transition: 0.5s;
    .zastupstva-bg {
      position: relative;
      border-radius: 15px;
      width: 100%;
    }
    .zastupstva-brand {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      width: 50%;
    }
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      top: 0;
      left: 0;
      background-image: linear-gradient(#0303039a, $main-color 96%);
      height: 100%;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

/* *****ZASTUPSTVA LINE***** */

.section-brands {
  margin-top: 20px;
  width: 100%;
  background-color: #272626;
  padding: 10px 20px;
  .brands-grid {
    width: 100%;
    margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    img {
      width: 100%;
    }
  }
}
/* *****ZASTUPSTVA PAGE***** */
.zastupstva-text {
  text-align: left;
  h5 {
    width: 80%;
    font-size: 1.3rem;
    color: #fff;
    border-left: 4px solid $red-color;
    padding-left: 10px;
    font-weight: 600;
    text-align: left;
    margin: 30px auto 5px auto;
  }
  img {
    width: 100%;
    border-radius: 15px;
  }
  a {
    font-size: 1.1rem;
    color: $red-color;
    padding-left: 50px;
  }
  .zastupstva-grid {
    width: 80%;
    margin: 0 auto;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    p {
      width: 100%;
      margin: 10px auto;
    }
  }
}
.zastupstva-links {
  width: 80%;
  margin: 10px 10%;
  li {
    margin: 5px auto;
    display: flex;
    gap: 5px;
    font-size: 1.3rem;
    color: $text-color;
    text-align: left;
    span {
      font-size: 1.3rem;
    }
  }
}
.zastupstva-img-box {
  width: 80%;
  margin: 0 10%;
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}
.zastupstva-buttons {
  display: flex;
  width: 80%;
  margin: 5px auto;
  gap: 10px;
  a {
    margin: 0;
  }
}

// *****************MEDIA QUERIES***************** //

// *****MINI TABLET****** //

@media only screen and (min-width: 650px) {
  // *****ZASTUPSTVA BUTTONS***** //
  .zastupstva-grid-btn {
    grid-template-columns: repeat(3, 1fr);
  }
  /* *****ZASTUPSTVA PAGE***** */
  .zastupstva-text {
    h5 {
      font-size: 1.4rem;
    }

    a {
      font-size: 1.3rem;
      padding-left: 0;
      width: 80%;
      margin: 5px 10%;
    }
    .zastupstva-grid {
      width: 85%;
      padding: 0 10px;
      margin: 10px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      div {
        display: flex;
        width: 100%;
        margin: 10px auto;
      }
      img {
        width: 95%;
        margin: 5px 2.5%;
      }
    }
  }
  .zastupstva-img-box {
    width: 80%;
    margin: 10px 10%;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  // *****ZASTUPSTVA BUTTONS***** //
  .zastupstva-grid-btn {
    width: 90%;
    margin: 10px auto;
    grid-template-columns: repeat(3, 1fr);
  }
  /* *****SECTION BRANDS***** */

  .section-brands {
    .brands-grid {
      width: 70%;
      margin: 15px auto;
      img {
        width: 80%;
      }
    }
  }
}
