.language {
  position: fixed;
  top: 9vh;
  right: 10px;
  z-index: 100;
  button {
    margin-bottom: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 3px solid #222;
    justify-content: center;
    transition: all 0.5s;
    img {
      width: 30px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
