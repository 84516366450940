.privacy-text {
  width: 100%;
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .privacy-text {
    width: 80%;
    margin: 10px auto;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .privacy-text {
    width: 90%;
    margin: 10px auto;
  }
}
