.navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  background-color: $main-color;
  backdrop-filter: blur(1px);
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  padding: 30px 20px;
  z-index: 10;
  .logo {
    width: 160px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.nav-links {
  list-style: none;
  li {
    margin: 10px 0;
    a {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      list-style: none;
      text-decoration: none;
    }
  }
}

.link-border {
  height: 1px;
  width: 20%;
  margin: 5px 40%;
  background-color: #a9a9a94e;
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 15;
  .bar {
    display: block;
    border-radius: 5px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    /*  */
    &:nth-child(1),
    &:nth-child(3) {
      width: 35px;
      height: 1.5px;
    }
    &:nth-child(2) {
      width: 25px;
      height: 1px;
    }
  }
}

.border {
  width: 50%;
  margin: 10px 25%;
  height: 1px;
  background-color: $border-color;
}
.long-border {
  width: 100%;
  margin: 10px 0;
  height: 1px;
  background-color: $border-color;
}

.red-border {
  width: 35%;
  margin: 20px auto;
  height: 1px;
  background-color: $red-color;
  opacity: 0.7;
}

#active {
  color: $red-color;
}

// -----MINI TABLET----- //

@media only screen and (min-width: 650px) {
}

@media only screen and (max-width: 1100px) {
  .hamburger {
    display: block;
    z-index: 100;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  .nav-links {
    position: fixed;
    right: -100%;
    top: 0;
    height: 100vh;
    flex-direction: column;
    background-color: $main-color;
    width: 60%;
    box-shadow: 2px 10px 6px $shadow-color;
    text-align: center;
    transition: 0.3s;
    padding: 60px 20px;
    z-index: 100;
    ul {
      margin: 20px 0;
    }
  }
  .nav-links.active {
    right: 0;
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  .logo {
    width: 180px;
  }
  .navbar {
    padding: 0 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 90;
  }

  .nav-links {
    display: flex;
    gap: 5px;
  }

  .nav-links li a {
    padding: 5px 15px;
    transition: ease 0.5s;
    font-size: 1.1rem;
    background: none;
  }

  .nav-links li a:hover {
    background-color: #e31f26;
    color: #fff !important;
    border-radius: 15px 5px 15px 5px;
  }
  .link-border {
    display: none;
  }
}

/* -----DESKTOP 1800----- */

@media only screen and (min-width: 1800px) {
  .logo {
    width: 180px;
  }
  .navbar {
    padding: 0 12%;
  }
}
