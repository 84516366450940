footer {
  width: 100%;
  text-align: center;
}

.footer-box {
  padding: 10px;
  h2 {
    margin-top: 10px;
    font-size: 1.2rem;
    color: $white-color;
    span {
      font-size: 1.2rem;
      color: $red-color;
      font-weight: 800;
    }
  }
  p,
  a {
    display: block;
    font-size: 1rem;
    color: #ababab;
    text-decoration: none;
    width: 90%;
    margin: 5px auto;
  }
  &:nth-child(4) a {
    color: $red-color;
  }
}

.footer-icons {
  width: 100%;
  padding: 20px 20px;
  margin-top: 10px;
  background-color: #212020;
  text-align: center;
  border-top: 1px solid #6666668b;
  a i {
    font-size: 1.8rem;
    color: #fff;
    margin: 5px 8px;
  }
  p {
    margin-top: 10px;
    font-size: 0.7rem;
    color: #fff;
    span {
      font-size: 0.7rem;
    }
  }
}

// *****************MEDIA QUERIES***************** //

// -----MINI TABLET----- //

@media only screen and (min-width: 650px) {
  .footer-box {
    padding: 10px;
    h2 {
      font-size: 1.6rem;

      span {
        font-size: 1.6rem;
      }
      p,
      a {
        font-size: 2.4rem;
        text-decoration: none;
      }
    }

    .footer-icons {
      a i {
        font-size: 3rem;
      }
      p {
        font-size: 1.4rem;
        .year {
          font-size: 1.3rem;
        }
      }
    }
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 25px 15%;
    width: 70%;

    h2 {
      font-size: 1.3rem;
    }
  }
  .footer-icons {
    p {
      font-size: 0.8rem;
      #year {
        font-size: 0.8rem;
      }
    }
  }
}

/* -----DESKTOP 1800+----- */

@media only screen and (min-width: 1800px) {
  .footer-grid {
    margin: 25px 22.5%;
    width: 55%;
  }
}
