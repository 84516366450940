.contact-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  margin: 20px auto;
  width: 90%;
  gap: 10px;
}

.contact-box {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
  background-color: #272727;
  border-radius: 15px;
  text-decoration: none;
  color: $white-color;
  transition: 0.5s;
  &:hover {
    background-color: #303030;
  }
  i {
    font-size: 1.4rem;
    color: $red-color;
  }
  p {
    font-size: 1.1rem;
    color: $white-color;
  }
}

.contact-worktime {
  padding: 10px 5px;
  text-align: center;
  background-color: #272727;
  border-radius: 15px;
  margin: 20px 5% 35px;
  width: 90%;
  h4 {
    font-size: 1.4rem;
    color: $white-color;
  }

  i {
    font-size: 1.4rem;
    color: $red-color;
  }
}

.section iframe {
  width: 90%;
  margin: 5px 5%;
  height: 300px;
  border-radius: 15px;
  filter: grayscale(100%);
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .section iframe {
    height: 350px;
  }

  /* -----SECTION FORM-----  */

  .form-title {
    font-size: 2.2rem;
  }
}
/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .contact-grid {
    margin: 0 auto;
    width: 90%;
    gap: 10px;
  }

  .contact-worktime {
    margin: 20px 5% 35px;
    width: 90%;
    gap: 10px;
  }

  .section iframe {
    width: 90%;
    margin: 5px 5%;
    height: 450px;
  }
}
