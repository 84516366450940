.cars-slide {
  width: 100%;
  margin: 5px 0;
  position: relative;
}

.btn-slide {
  background-color: #222;
  padding-top: 7px;
  border-radius: 50%;
  transition: 0.5s;
  border: none;
  height: 50px;
  width: 50px;
  /*  box-shadow: 0px 2px 12px #0000004d; */
}

.fi {
  color: #fff;
  font-size: 1.6rem;
}

#left-slide {
  position: absolute;
  top: -3%;
  left: 2%;
  z-index: 9;
}

#right-slide {
  position: absolute;
  top: -3%;
  right: 2%;
  z-index: 9;
}

.cars-slide .slider .cars-box:first-child {
  display: grid;
}
.cars-slide .slider .cars-box {
  display: none;
}

.cars-slide {
  margin-top: 15px;
}

.cars-box {
  overflow: hidden;
  margin: 10px 20px;
  border-radius: 15px;
  box-shadow: 1px 2px 10px #11111179;
  border: 3px solid #333;
  transition: ease-in 0.5s;

  .img-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    overflow: hidden;
    border-radius: 15px;
    padding: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
      overflow: hidden;
      position: relative;
    }
  }
  .cars-info {
    text-align: center;
    padding: 40px 20px 20px 0px;
    h3 {
      font-size: 1.4rem;
      color: #fff;
      z-index: 25;
    }
    ul {
      margin: 0 20px;
      li {
        display: flex;
        gap: 5px;
        font-size: 1.1rem;
        text-align: left;
        color: #bbb;
        span {
          font-size: 1.1rem;
        }
      }
    }
  }
}
/* -----SECTION CAR GALLERY----- */
.grid-4 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  width: 100%;
  margin: 10px 0 10px;
  padding: 20px;
  .gallery-img {
    position: relative;
    width: 100%;
    border-radius: 5px;
    height: 90%;
    margin: 10px 0;
    object-fit: cover;
    object-position: center;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 760px) {
  /* -----NAŠI PROKEJTI----- */

  .fi {
    font-size: 3rem;
  }

  #left-slide {
    position: absolute;
    top: -7%;
    left: 0%;
  }

  #right-slide {
    position: absolute;
    top: -7%;
    right: 0%;
  }

  .cars-box {
    display: grid;
    grid-template-columns: 2.6fr 1.6fr;
    .cars-info {
      h3 {
        font-size: 1.5rem;
        margin-top: 10px;
      }
      p {
        font-size: 1.3rem;
      }
      ul {
        li {
          font-size: 1.2rem;
          color: #bbb;
        }
      }
    }
  }

  .cars-box img {
    height: 100%;
  }

  /* -----SECTION CAR GALLERY----- */
  .grid-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
/* DESKTOP */

@media only screen and (min-width: 1024px) {
  #left-slide {
    position: absolute;
    bottom: 45%;
    left: 2%;
  }

  #right-slide {
    position: absolute;
    bottom: 45%;
    right: 2%;
  }

  .cars-box {
    display: grid;
    grid-template-columns: 3fr 2.5fr;
    margin: 0 5%;
    width: 90%;
    img {
      height: 100%;
      object-fit: cover;
      object-position: bottom !important;
    }

    .cars-info {
      text-align: center;

      h3 {
        font-size: 2.2rem;
      }
      p {
        font-size: 1.6rem;
      }
      ul {
        width: fit-content;
        padding-left: 10%;
        text-align: center;
        li {
          font-size: 1.4rem;
        }
      }
    }
  }

  /* -----SECTION CAR GALLERY----- */
  .grid-4 {
    width: 90%;
    margin: 10px 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}
