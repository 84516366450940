.section h4 {
  font-size: 1.4rem;
  color: #e31f26;
  font-weight: 700;
  margin-bottom: 20px;
}
form {
  margin: 0 5%;
  width: 90%;
  padding: 10px 20px;
  h4 {
    font-size: 1.8rem;
    color: #e31f26;
    font-weight: 700;
    margin-bottom: 20px;
  }
  label {
    width: 100%;
    font-size: 1.2rem;
    color: #fff;
    span {
      color: #e31f26;
      font-size: 1.1rem;
    }
  }
  input,
  textarea,
  select,
  option {
    position: relative;
    margin-top: 5px;
    width: 100%;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: #444;
    margin-bottom: 15px;
    resize: none;
  }
  option {
    color: #fff;
  }
  input {
    &::placeholder {
      color: #000;
      :last-child {
        background-color: #e31f26 !important;
        border: 1px solid #e31f26;
        color: #fff;
        font-weight: 800;
        margin-top: 20px;
      }
    }
  }
  textarea {
    &::placeholder {
      color: #000;
    }
  }
  input:last-child {
    color: $white-color;
    font-weight: 600;
    background-color: #e31f26;
    border: none;
  }
  input:last-child:hover {
    color: #e31f26;
    background-color: #303030 !important;
  }
  input:last-child:active {
    color: #303030;
    background-color: #696666 !important;
  }
}

.section input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  padding-top: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}

#error-message {
  display: block;
  color: $red-color;
  font-size: 1rem;
  text-align: start;
  margin-left: 10px;
  margin-top: -10px;
}
.thank-you {
  width: 80%;
  margin: 10px 10%;
  padding: 15px;
  text-align: center;
  background-color: #17a255;
  border-radius: 15px 5px 15px 5px;
  border: 1px solid #17a255;

  h1 {
    font-size: 1.4rem;
    color: #fff;
    margin-bottom: 5px;
    i {
      font-size: 1.2rem;
      color: #333;
    }
  }
  p {
    font-size: 1.2rem;
    color: $main-color;
    font-weight: 600;
  }
}
.error-box {
  width: 80%;
  margin: 10px 10%;
  padding: 15px;
  text-align: center;
  background-color: #db1812;
  border-radius: 15px 5px 15px 5px;
  border: 1px solid #333;
  h1 {
    font-size: 1.4rem;
    color: #fff;

    margin-bottom: 5px;
    i {
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 1.2rem;
    color: $main-color;
    font-weight: 600;
    a {
      width: 100%;
      i {
        font-size: 1.2rem;
      }
    }
  }
}

// *****************MEDIA QUERIES***************** //

// *****MINI TABLET****** //

@media only screen and (min-width: 650px) {
  .section form {
    margin: 0 2.5%;
    width: 95%;
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  .section form {
    margin: 0 auto;
    width: 90%;
  }
}
