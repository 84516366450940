.about-border {
  display: block;
  margin: 20px auto;
  width: 20%;
  height: 1px;
  background-color: #aaaaaa6b;
}

/* -----ABOUT US----- */

.about-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 20px;
  width: 100%;
}

.about-box {
  width: fit-content;

  h5 {
    font-size: 1.2rem;
    font-weight: 800;
    color: #fff;
    span {
      margin-top: 25px;
      font-size: 1.2rem;
      font-weight: 800;
      color: #fff;
    }
  }
  &:first-child {
    display: flex;
    align-items: center;
    padding-top: 20px;
    flex-direction: column;
  }
}

.about-box h5 span {
  color: #e31f26;
}
.about-box img {
  width: 80%;

  margin: 10px 10%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  transition: 0.5s;
}

#section-about h6 {
  font-size: 1rem;
  color: #eee;
  font-weight: 500;
  margin-top: 15px;
  font-variant: small-caps;
}

.vrijednosti-box {
  list-style: none;
  margin-top: 15px;
  li {
    font-size: 1.1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px;
    margin-top: 5px;
    i {
      font-size: 1.4rem;
      color: $red-color;
    }
  }
}

/* -----SECTION NAS TIM----- */

.team-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 20px;
}
.team-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px 0;
  line-height: 0.5;
  h5 {
    font-size: 1.2rem;
    color: $white-color;
  }
  span {
    margin: 10px 0;
    font-size: 1rem;
    color: #ffff;
    i {
      font-size: 1.1rem;
      color: $red-color;
      margin-right: 10px;
    }
  }
  a {
    font-size: 1rem;
    color: $text-color;
    margin: 5px 0;
    width: fit-content;
    transition: all 0.5s;
    &:hover {
      color: $red-color;
    }
    i {
      font-size: 1rem;
      margin-right: 5px;
      color: $red-color;
    }
  }
}
.team-box {
  margin-top: 10px;
  img {
    width: 100%;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    transition: 0.5s;
    &:hover {
      transform: scale(1.6);
      border-radius: 15px;
      margin-bottom: 50px;
      margin-top: 25px;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  /* -----SECTION NAS TIM----- */
  .team-grid {
    grid-template-columns: repeat(2, 1fr);
    .team-box div {
      h5 {
        font-size: 1.4rem;
      }
      span,
      p,
      a {
        font-size: 1.1rem;
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* -----ABOUT US----- */
  .about-grid {
    width: 90%;
    margin: 10px 5%;

    grid-template-columns: 2.6fr 1.6fr;
    .about-box {
      h5 {
        font-size: 1.2rem;
      }
      img {
        width: 80%;
        margin: 10% auto;
      }
    }
  }

  /* -----SECTION NAS TIM----- */

  .team-grid {
    width: 90%;
    margin: 10px 5%;
    grid-template-columns: repeat(3, 1fr);
  }
  .team-box div {
    h5 {
      font-size: 1.3rem;
    }
    span,
    p,
    a {
      font-size: 1.1rem;
    }
  }
}
