/* *****RECENZIJE***** */
.recenzije-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  .recenzije {
    width: 100%;
    padding: 20px;
    h2 {
      font-size: 1.2rem;
      margin-top: 10px;
    }
    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }
    p {
      font-size: 1.1rem;
    }
    i {
      font-size: 1rem;
      color: $review-color;
    }
  }
}

// *****AUTOCONNECT***** //
.grid-3 {
  margin: 20px 10px;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .autoconnect-box {
    width: 100%;
    background-color: #272727;
    padding: 10px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    transition: all 0.5s;
    i {
      color: $red-color;
      font-size: 1.4rem;
    }
    h5 {
      color: $white-color;
      font-size: 0.8rem;
      font-weight: 300;
    }
    &:hover {
      background-color: #343333;
      color: $red-color;
      font-weight: 700;
    }
  }
}

// *****PROJEKTI***** //

.projekt-box {
  width: 100%;
  padding: 20px;
  text-align: left;
  margin-bottom: 30px;
  h4 {
    font-size: 1.3rem;
    color: $white-color;
    padding-left: 10px;
    margin-bottom: 5px;
    border-left: 3px solid $red-color;
  }
  h6 {
    font-size: 1.1rem;
    color: $white-color;
    font-weight: 400;
    margin-bottom: 15px;
  }
  ul li {
    display: flex;
    gap: 5px;
    color: $text-color;
    font-size: 1rem;
    span {
      font-size: 1rem;
    }
  }

  .projekt-img-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 5px;
    img {
      width: 100%;
      height: 100%;
      margin: 10px auto;
      border-radius: 5px;
      object-fit: cover;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 650px) {
  /* *****RECENZIJE***** */
  .recenzije-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    margin: auto;
    .recenzije {
      width: 100%;
      padding: 20px;
      margin-bottom: 30px;
      h2 {
        font-size: 1.3rem;
        margin-top: 10px;
      }
      img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
      }
      p {
        font-size: 1.1rem;
        margin-bottom: 15px;
      }
      i {
        font-size: 1rem;
        margin-bottom: 25px;
        color: $review-color;
      }
    }
  }
  // *****AUTOCONNECT***** //
  .grid-3 {
    width: 90%;
    margin: auto;
    .autoconnect-box {
      i {
        font-size: 1.6rem;
      }
      h5 {
        font-size: 1rem;
      }
    }
  }
  // *****PROJEKTI***** //
  .projekt-grid {
    width: 90%;
    margin: 0 auto;
    .projekt-box {
      display: grid;
      grid-template-columns: 1.5fr 2fr;
      gap: 10px;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  // *****AUTOCONNECT***** //
  .grid-3 {
    .autoconnect-box {
      i {
        font-size: 1.5rem;
      }
      h5 {
        font-size: 1.2rem;
      }
    }
  }
}
