/* *****COOKIES***** */
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.1rem;
  background: #222;
  box-shadow: 0px -2px 10px 2px #b72d2d3c;
  border-top: 3px solid $red-color;
  color: $white-color;
  padding: 10px 12%;
  text-align: center;
  z-index: 50;

  button {
    text-decoration: underline;
    color: $red-color;
    background: none;
    font-size: 1.1rem;
    padding: 5px 2px;
    border: none;
  }
  .cookies-policy-btn {
    display: block;
    color: $red-color;
    padding-left: 5px;
    text-decoration: underline;
  }
  .close-cookies {
    position: absolute;
    right: 20px;
    text-decoration: none;
    font-size: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* *****MAIN***** */

main {
  position: relative;
  width: 100%;
  margin-top: 8vh;
  .main-box {
    position: absolute;
    bottom: -1%;
    background-color: $main-color;
    width: 100%;
    height: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 5;
  }
}

.main-title {
  position: absolute;
  left: 5%;
  bottom: -20%;
  z-index: 5;
  width: 90%;

  h1 {
    color: $white-color;
    font-size: 2rem;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p {
    width: 70%;
    color: $text-color;
    font-size: 1.2rem;
    margin-bottom: 7vh;
  }
}

.btn {
  margin-left: 5%;
  margin-bottom: 10vh;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  background-color: $red-color;
  color: $white-color;
  padding: 10px 20px;
  border-radius: 15px 5px 15px 5px;
  transition: all 0.7s;
  &:hover {
    color: $main-color;
    opacity: 0.8;
  }
}

.main-img {
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    position: relative;
    object-position: center;
  }
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background-image: linear-gradient(#03030312, $main-color 95%);
    height: 100%;
  }
}

// *****************SECTIONS***************** //
.section {
  padding: 20px 10px;
  margin: 5px auto;
  width: 100%;
  text-align: center;
}
.section h1 {
  font-size: 1.9rem;
  color: $white-color;
}

.section h2 {
  @include title-h2;
}

.section h3 {
  @include title-h3;
}

.section p {
  @include paragraph;
}
.link {
  font-size: 2rem;
  color: $red-color;
  font-weight: 500;
}

/* *****IMAGE POPUP*****  */

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: #222222ea;
  align-items: center;
  z-index: 1000;
}

#popup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  img {
    border-radius: 15px;
  }
}

#close {
  position: absolute;
  top: 5px;
  color: #fff;
  font-size: 2rem;
  right: 20px;
  cursor: pointer;
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 760px) {
  .go-back-btn {
    margin-left: 10% !important;
  }
  /* *****COOKIES***** */
  .cookies {
    padding: 10px 10px;

    .cookies-policy-btn {
      display: inline-block;
    }
  }
  // *****SECTIONS***** //

  .section {
    padding: 20px 10px;
    width: 90%;
    margin: 5px auto;
    text-align: center;
  }

  .section h1 {
    font-size: 2rem;
    color: $white-color;
  }
  .section h2 {
    @include title-h2;
    font-size: 1.6rem;
  }

  .section h3 {
    @include title-h3;
    font-size: 1.4rem;
  }

  .section p {
    @include paragraph;
    font-size: 1.4rem;
  }

  /* *****IMAGE POPUP*****  */
  #popup {
    width: 60%;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .go-back-btn {
    margin-left: 15% !important;
  }
  // *****SECTIONS***** //

  .section {
    padding: 20px;
    width: 60%;
    margin: 10px 20%;
  }
  .section h1 {
    font-size: 2.1rem;
    color: $white-color;
  }
  .section h2 {
    @include title-h2;
    font-size: 2rem;
  }

  .section h3 {
    @include title-h3;
    font-size: 1.3rem;
  }

  .section p {
    @include paragraph;
    font-size: 1.2rem;
  }
  .link {
    font-size: 2rem;
  }
  /* *****IMAGE POPUP*****  */
  #overlay {
    z-index: 100;
  }

  #popup {
    width: fit-content;
    height: 70%;
    border: 2px solid #222;
  }
}
