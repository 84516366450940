.servis-img {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 95%;
  margin: 10px 2.5%;
  gap: 15px;
  img {
    width: 100%;
    border-radius: 30px;
  }
}
.servisni-centar {
  margin-top: 22vh;
}

.servis-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  width: 100%;
  margin: 20px 0 10px;
  padding: 10px;

  .servis-box {
    background-color: #272727;
    padding: 10px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.2;
    transition: ease-in-out 0.5s;
    border-radius: 15px;
    i {
      color: $red-color;
      font-size: 1.4rem;
    }
    h5 {
      color: $white-color;
      font-weight: 300;
      font-size: 0.85rem;
    }
    &:hover {
      background-color: #343333;
      color: $red-color;
      font-weight: 700;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 660px) {
  .servis-img {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    width: 100%;
    margin: 10px auto;
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  .servis-img {
    width: 85%;
    margin: 10px auto;
  }
  .servis-grid {
    width: 85%;
    margin: 20px auto 10px;
  }
}
