.sound-panthera-box {
  .panthera-app-btn {
    width: 80%;
    background-color: #272727;
    padding: 10px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.2;
    font-size: 1rem;
    color: $white-color;
    transition: all 0.5s;
    border-radius: 15px;
    margin: 5px auto 10px;
    i {
      color: $white-color;
      font-size: 1.4rem;
      transition: all 0.5s;
    }
    &:hover {
      background-color: #343333;
      color: $red-color;

      i {
        color: $red-color;
      }
    }
  }
  h5 {
    font-size: 1.2rem;
    color: $red-color;
    margin-bottom: 10px;
    font-weight: 500;
  }
}

.sound-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  .sound-images {
    width: 80%;
    object-fit: cover;
    border-radius: 15px;
    margin: 10px auto;
  }
  .sound-modul-video {
    width: 80%;
    max-width: 100%;
    height: 100%;
    margin: 0px auto;
    border-radius: 15px;
  }
}

.usluge-btn {
  width: 100%;
  text-align: center;
  margin: 30px auto 10px;
  a {
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    background-color: $red-color;
    color: $white-color;
    padding: 10px 20px;
    border-radius: 15px 5px 15px 5px;
    transition: all 0.7s;
    &:hover {
      color: $main-color;
      opacity: 0.8;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 660px) {
  .sound-panthera-box {
    width: 80%;
    margin: 5px auto;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    .panthera-app-btn {
      width: 100%;
      font-size: 1.2rem;
      padding: 10px 20px;
      margin: 5px auto 10px;
    }
    h5 {
      font-size: 1.6rem;
    }
  }
  .sound-grid {
    width: 80%;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    .sound-images {
      width: 100%;
      object-fit: cover;
      height: 300px;

      margin: 10px auto;
    }
    .sound-modul-video {
      width: 100%;
      height: 100%;
      margin: 10px auto;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .sound-panthera-box {
    width: 75%;
  }
  .sound-grid {
    width: 75%;
    .sound-modul-video {
      width: 100%;
      margin: 10px auto;
    }
  }
}
