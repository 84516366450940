.car-item {
  width: 95%;
  margin: 10px auto;
  background-color: #35353568;
  padding: 10px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  border-radius: 25px 10px 25px 10px;
  gap: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px 5px 15px 5px;
  }
  .car-item-info {
    margin: 0;
    text-align: left;
    display: grid;
    grid-template-rows: auto auto auto;
  }
  h3 {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  .car-item-more-info {
    font-size: 0.8rem;
    p {
      margin: 0;
      font-size: 0.9rem;
    }
  }
  h4 {
    margin: 5px 0 0;
    span {
      color: $white-color;
      font-size: 1rem;
    }
  }
}
