/* NEWS & NEWS PAGES */

.novosti-box {
  width: 90%;
  margin: 15px auto;
  padding: 10px;
  text-align: left;
  img {
    width: 100%; /* Širina slike */

    object-fit: cover;
    object-position: center;
  }
  .category {
    margin-top: -5px;
    color: $red-color;
  }
  h5 {
    font-size: 1.2rem;
    color: $white-color !important;
    margin: 5px 0 !important;
  }
  span {
    margin: 0;
    color: $border-color;
  }
}

.filter-controls {
  padding: 10px 20px;
  text-align: left;
  select {
    margin: 5px 0;
    padding: 10px 20px;
    color: #333;
    border: none;
    border-radius: 15px 5px 15px 5px;
  }
}
.pagination {
  button {
    background: none;
    font-size: 1.5rem;
    border: none;
    margin: 0 5px;
    color: $red-color;
    font-weight: 600;
    &:nth-child(2) {
      color: $text-color;
      font-weight: 500;
    }
  }
}

// -----MINI TABLET----- //

@media only screen and (min-width: 650px) {
  .novosti-pages {
    width: 80%;
    margin: 0 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .novosti-box {
    width: 100%;
    margin: 15px 0;
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  .novosti-pages {
    width: 70%;
    margin: 0 15%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .novosti-box {
    width: 100%;
    margin: 15px 0;
  }
}

/* -----DESKTOP 1800+----- */

@media only screen and (min-width: 1800px) {
}
