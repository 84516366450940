* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 62.5%; */
  font-family: "Nunito", sans-serif;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  background-color: $main-color;
  position: relative;
}

.error-message {
  color: $red-color;
  font-size: 0.8rem;
  margin: -3px 0 5px;
}

.left-margin {
  text-align: left;
}
.top-margin {
  margin-top: 20px;
}
