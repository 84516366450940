// *****NAŠE USLUGE***** //
.usluge-grid {
  gap: 10px;
  width: 100%;
  margin: 20px 0 10px;
  padding: 20px 0 5px;
  .services-box {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    transition: ease-in-out 0.5s;
    padding: 30px 0;
    height: fit-content;

    .services-img {
      position: absolute;
      left: 0;
      top: 0;
      height: 60px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }
    .services-title {
      cursor: pointer;
      margin: 10px 0;
      h5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 10px 0 20px;
        z-index: 5;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #000000e7;
        color: $white-color;
        font-size: 1.2rem;
      }
      i {
        position: absolute;
        font-size: 2rem;
        top: 60%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 5;
        color: $red-color;
      }
    }
  }
}
@keyframes usluge-animation {
  0% {
    transform: translateY(-500px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.usluge-box {
  display: none;
  animation: usluge-animation 0.35s ease;
  z-index: 3;
  padding: 20px 0;
  margin-top: 20px;
  background-color: #000000da;
  z-index: 2;
  text-align: left;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  ul {
    text-align: left;
    margin: 20px 10% 0;

    li {
      font-size: 1.2rem;
      color: #eee;
    }
  }
  .usluge-img {
    width: 90%;
    margin: 40px 5% 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
  .usluge-btn {
    width: 100%;
    text-align: center;
    margin: 30px auto 10px;
    a {
      text-align: center;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 600;
      background-color: $red-color;
      color: $white-color;
      padding: 10px 20px;
      border-radius: 15px 5px 15px 5px;
      transition: all 0.7s;
      &:hover {
        color: $main-color;
        opacity: 0.8;
      }
    }
  }
  &.open {
    opacity: 1;
    transform: translateY(0);
    display: block;
  }
}
/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 650px) {
  // *****NAŠE USLUGE***** //
  .usluge-grid {
    width: 95%;
    margin: 20px auto 10px;

    grid-template-columns: repeat(1, 1fr);
    .usluge-box {
      .usluge-img {
        width: 80%;
        margin: 40px 10% 10px;
        gap: 5px;
        img {
          width: 95%;
          margin: 0 auto;
        }
      }
    }
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  .usluge-grid {
    width: 85%;
    margin: 20px auto 10px;
  }
}
