/* ADMIN LOGIN*/
.login {
  width: 90%;
  margin: 5px auto;
}

/* ADMIN HOME */
.admin-btn-group {
  width: 100%;
  .admin-btn {
    display: inline-block;
    width: 80%;
    margin: 5px auto;
    background-color: #333;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 20px;

    border-radius: 15px 5px 15px 5px;
  }
}

.form {
  width: 80%;
  margin: 10px auto;
  color: #fff;
  input,
  select,
  input,
  textarea {
    width: 100%;
    resize: none;
    margin: 5px 0;
    background-color: #444;
    color: #fff;
    padding: 10px 20px;
    border-radius: 15px 5px 15px 5px;
  }
  label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }
}
.news-image-alt {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
.edit-btn {
  background: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #17a255;
  border: none;
  margin: 0 5px 5px;
}

.add-btn {
  padding: 10px 20px;
  border-radius: 15px 5px 15px 5px;
  background-color: #17a255;
  border: none;
  margin: 5px;
}
.publish-btn {
  padding: 10px 20px;
  border-radius: 15px 5px 15px 5px;
  background-color: #1768e2;
  border: none;
  margin: 5px;
}
.remove-btn {
  background: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #db1812;
  border: none;
  margin: 0 5px 5px;
}

.admin-news-list {
  width: 90%;
  margin: 5px 5%;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
  align-items: center;
  img {
    width: 5rem;
  }
  h5 {
    margin: 0 5px;
    font-size: 0.9rem;
    text-align: left;
    color: $text-color;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides any overflowing text */
    text-overflow: ellipsis;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.news-preview {
  width: 100%;
  text-align: left;
  padding: 20px 10px;
  img {
    width: 100%;
    margin: 0;
  }

  .image-author {
    color: $border-color;
  }
  .category {
    font-size: 1rem;
    color: $red-color;
  }
  h3 {
    width: 100%;
    font-size: 1.4rem;
  }
  p {
    width: 100%;
    margin: 10px 0;
    color: $text-color;
  }
  h4 {
    color: $white-color;
    font-weight: 700;
    margin: 5px 0 0;
  }
  h5 {
    margin-top: 10px;
    font-size: 0.9rem;
    color: $red-color;
  }
  .date {
    font-size: 1rem;
    color: $text-color;
  }
  a {
    color: $red-color;
  }
  .car-contact {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    .contact-mail-box {
      text-align: center;
      padding: 10px 12px;
      background-color: $border-color;
      border-radius: 15px 5px 15px 5px;
      h4 {
        font-size: 0.9rem;
      }
      a {
        font-size: 0.9rem;
        color: $text-color;
      }
    }
  }
}
/* ADD CARS */
.input-grid-4 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 5px;
  align-items: center;
  input {
    background: #444 !important;
    border: none;
  }
  p {
    &:first-of-type {
      margin-left: -2px;
      margin-right: 10px;
    }
  }
}
.input-grid-2 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  align-items: center;
  input {
    background: #444 !important;
    border: none;
  }
  p {
    &:first-of-type {
      margin-left: -2px;
      margin-right: 10px;
    }
  }
}

// *****************MEDIA QUERIES***************** //

// -----MINI TABLET----- //

@media only screen and (min-width: 650px) {
  /* ADMIN LOGIN*/
  .login {
    width: 80%;
    margin: 5px auto;
  }
  .news-preview {
    width: 80%;
    margin: 25px auto;
  }
}

/* -----DESKTOP----- */

@media only screen and (min-width: 1024px) {
  /* ADMIN LOGIN*/
  .login {
    width: 60%;
    margin: 5px auto;
  }
  .news-preview {
    width: 60%;
    margin: 25px auto;
  }
}

/* -----DESKTOP 1800+----- */

@media only screen and (min-width: 1800px) {
}
