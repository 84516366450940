$title-h1-size: 2rem;
$title-h2-size: 1.6rem;
$title-h3-size: 1.2rem;

@mixin button {
  padding: 10px 15px;
  color: $red-color;
  border-radius: 30px;
}

@mixin title-h2 {
  padding: 0 20px;
  font-size: $title-h2-size;
  font-weight: 700;
  margin-bottom: 10px;
  color: $white-color;
}

@mixin title-h3 {
  margin-top: 25px;
  font-size: $title-h3-size;
  color: $white-color;
  border-left: 4px solid $red-color;
  width: fit-content;
  padding-left: 5px;
  margin: 10px auto;
}

@mixin paragraph {
  font-size: 1.2rem;
  color: $text-color;
  width: 80%;
  margin: 10px 10%;
}
